import { Injectable } from '@angular/core';
import { Settings } from 'src/app/app.settings.model';

@Injectable()
export class AppSettings {

public settings = new Settings(
        'Fotovoltaica',
        'Fotovoltaica',
        {
            navbarIsFixed: true,
            footerIsFixed: true,
            sidebarIsFixed: true,
            skin:'combined',  //light , dark, blue, green, combined, purple, orange, brown, grey, pink
        },
        {
            googleMapsAPIKey: 'AIzaSyD57tSVhrq5N_MRrWzJVh_n4LCJcdCubkY',
            feature_googleMaps: true,
            googleAnalytics: '',
            feature_googleAnalytics: false,
            googleTagManager: '',
            feature_googleTagManager: true,
            feature_preview_dialog: false,
            protocol: 'https',
            host: 'app.energysequence.com',
            port: 443,
            calculatorEndpoint: 'v2/pv/',
            simplifiedEndpoint: 'v2/ssc/',
            loginEndpoint: 'v2/pvauth/',
            tosURL: 'http://gashogar.info/politica-de-privacidad/',
        },
       {
            id: '15',
            name: 'Gas Hogar',
            email: 'gashogarsolar@gashogar.info',
            web: 'http://gashogar.info/',
            logo: 'assets/img/app/logo.png',
            instagram: '',
            twitter: 'https://twitter.com/gashogarenergia?lang=es',
            youtube: 'https://www.youtube.com/channel/UC_AsoZUoFRnrS-BWEPIrBwQ',
            linkedin:'https://www.linkedin.com/company/gashogarenerg%C3%ADa/?originalSubdomain=es',
            facebook: 'https://es-es.facebook.com/gashogarenergia/',
            privacyPolicies: 'http://gashogar.info/politica-de-privacidad/',
            backgroundPhoto: '/assets/img/app/background.jpg',
            buttonStyle: 'btn-outline-primary',
            fontFamily: '',
            navbarLogoDimension: '',
            showHeader: 1,
            showFooter: 1,
            welcome_text: 'Calcula tu instalación solar fotovoltaica',
            promotion_text: '',
            telephone_required: 1,
            name_required: 0,
            cups_required: 0,
            allow_login: 1,
            inital_map_latitude: 36.7192989,
            inital_map_longitude: -4.418461,
            investment_renting: 1,
            simplified_mode: false,
            home_costs: [50, 100, 200, 400, 1000],
            company_costs: [50, 100, 200, 400, 1000],
            feature_installation_costs: false,
            feature_cost_saving_percent: true
        }
    )
}

